import { Customer } from './../../model/customer';
import { UserService } from './../../services/user.service';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CustomersService } from '../../services/customers.service';

@Component({
  selector: 'app-header-three',
  templateUrl: './header-three.component.html',
  styleUrls: ['./header-three.component.scss']
})
export class HeaderThreeComponent implements OnInit {

  @Input() class: string = 'header-2';
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  public stick: boolean = false;
  customer: Customer

  constructor(public customersService: CustomersService) { }

  ngOnInit(): void {
    this.customersService.customer.subscribe(cus => {
      this.customer = cus; 
      // console.log('this.customer', this.customer);
      // customer
    });

    // let customer = this.customerSubject.value;
    // console.log('--- customer', customer);
    
    //localStorage.setItem('customer', JSON.stringify(customer));
    //this.customerSubject.next(customer);
    this.customersService.getCustomerPoint().subscribe(
      (data: any) => {
        // console.log('data', data);
        this.customer.point = +data.data.point;
        localStorage.setItem('customer', JSON.stringify(this.customer));
        this.customersService.customerSubject.next(this.customer);
      })
  }

  logout(){
    this.customersService.logout();
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

}
