import { Customer } from './../../../shared/model/customer';
import { CustomersService } from './../../../shared/services/customers.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { FacebookLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  socialUser!: SocialUser;

  constructor(
    private loader: LoadingBarService,
    private formBuilder: FormBuilder,
    private customersService: CustomersService,
    private toastrService: ToastrService,
    private socialAuthService: SocialAuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        if (params.code && params.state === 'login20220') {
          const setCustomer = (res) => this.setCustomer(res);

          this.loader.start();
          this.customersService.loginSocial({Token: params.code, Provider: 'LINE'})
            .subscribe(
              (data: any) => {
                console.log(data);
                setCustomer(data).then((customer) => {
                  console.log(customer);
                  localStorage.setItem('customer', JSON.stringify(customer));
                  this.customersService.customerSubject.next(customer);
                  this.router.navigate(['/home']);
                });
                this.loader.stop();
              },
              error => {
                console.log('error', error);
                if (error.status === 401) {
                  this.toastrService.error('login invalid.', null, {
                    positionClass: 'toast-bottom-center'
                  });
                }
                this.loader.stop();
              });
        }
      }
      );
  }

  get f() { return this.form.controls; }

  ngOnInit(): void {
    console.log('ngOnInit');
    // const loader = this.loader.start();
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.socialAuthService.authState.subscribe((user) => {
      console.log('user', user);
      if(user){
        this.socialUser = user;
        const setCustomer = (res) => this.setCustomer(res);
        this.loader.start();
        this.customersService.loginSocial({Token: user.authToken, Provider: 'FACEBOOK'})
          .subscribe(
            (data: any) => {
              console.log(data);
              setCustomer(data).then((customer) => {
                console.log(customer);
                localStorage.setItem('customer', JSON.stringify(customer));
                this.customersService.customerSubject.next(customer);
                this.router.navigate(['/home']);
              });
              this.loader.stop();
            },
            error => {
              console.log('error', error);
              if (error.status === 401) {
                this.toastrService.error('login invalid.', null, {
                  positionClass: 'toast-bottom-center'
                });
              }
              this.loader.stop();
            });
      }
      
    });

    // this.socialAuthService.(FacebookLoginProvider.PROVIDER_ID).then(accessToken => console.log(accessToken));

    // this.socialAuthService.authState.subscribe((user) => {
    //   this.socialUser = user;
    //   console.log('authState', user);
    // });
    // this.socialAuthService.authState
  }

  onSubmit() {
    console.log('onSubmit');

    this.submitted = true;

    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      console.log('invalid');
      return;
    }

    // const setCustomer()
    const setCustomer = (res) => this.setCustomer(res);

    this.loader.start();
    this.customersService.login(this.f.email.value, this.f.password.value)
      .subscribe(
        (data: any) => {
          this.loader.stop();
          console.log(data);
          if(data.status == "Error"){
            this.toastrService.error(data.message, null, {
              positionClass: 'toast-bottom-center'
            });
            return;
          }

          setCustomer(data).then((customer) => {
            console.log(customer);
            localStorage.setItem('customer', JSON.stringify(customer));
            this.customersService.customerSubject.next(customer);
            this.router.navigate(['/home']);
          });   
        },
        error => {
          console.log('error', error);
          if (error.status === 401) {
            this.toastrService.error('email or password is invalid.', null, {
              positionClass: 'toast-bottom-center'
            });
          }
          this.loader.stop();
        });
  }

  private setCustomer(data: any) {
    console.log('data', data);
    
    return new Promise<Customer>((resolve, reject) => {
      const cus = data.customer?.customers;
      const customer = new Customer();
      customer.id = cus?.customerId;
      customer.email = cus?.email;
      customer.firstName = cus?.firstname;
      customer.lastName = cus?.lastname;
      customer.privilegeId = cus?.privilegeId;
      customer.authType = cus?.authType;
      customer.token = data.token;
      customer.point = cus?.point;
      customer.isCollectPoint = cus?.privilege.isCollectPoint;
      // customer.is = data.point;
      resolve(customer);
    })
  }

  facebookLogin(e: Event) {
    e.stopPropagation();
    e.preventDefault();
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  lineLogin(e: Event) {
    e.stopPropagation();
    e.preventDefault();
    window.open("https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657820026&redirect_uri=https%3A%2F%2Fpuiyahubkaset.com%2Flogin&state=login20220&scope=profile%20openid%20email","_self");
    // this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }


  // loginWithFacebook(): void {
  //   this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  // }
  // signOut(): void {
  //   this.socialAuthService.signOut();
  // }

}
