import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

/** Pass untouched request through to the next request handler. */
@Injectable()
export class BaseUrlHttpInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (/^(http(s)?:\/\/|.\/)/i.test(req.url)) {
            return next.handle(req);
        }

        let requestUrl = req.url;

        req = req.clone({
            url: environment.API_ENDPOINT + requestUrl
        });

        return next.handle(req);
    }

}