
<section class="login-page section-b-space">
    <div class="container">
        <div class="row">
            <div id="privacy-policy">
                <section class="header">
                    <div class="container">
                        <h1>นโยบายคุ้มครองข้อมูลส่วนบุคคล</h1>
                        <div class="privacy-date">แก้ไขล่าสุดเมื่อวันที่ 13 พ.ย. 2565</div>
                    </div>
                </section>
                <section class="privacy-content">
                    <div class="container">
                        <div class="content">
                            <div class="privacy-intro">
                                <p>HubKaset ("ร้านค้า", "เรา")ตระหนักถึงความสำคัญของการปกป้องข้อมูลส่วนบุคคลขอผู้ใช้บริการ
                                    (&ldquo;ลูกค้า&rdquo; หรือ &ldquo;คุณ&rdquo;) และเคารพในสิทธิของลูกค้าทุกท่าน
                                    และเพื่อปกป้องข้อมูลส่วนบุคคลของคุณ
                                    โดยร้านค้าขอแนะนำให้คุณอ่านและทำความเข้าใจนโยบายความเป็นส่วนตัวฉบับนี้โดยละเอียด
                                    เพื่อให้คุณเข้าใจถึงวิธีการที่เราใช้ในการเก็บรวบรวม ใช้ และ/หรือ เปิดเผย ข้อมูลส่วนบุคคลของคุณ
                                    รวมไปถึงสิทธิของคุณ และเงื่อนไขต่างๆ ที่เกี่ยวข้อง ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.
                                    2562</p>
                            </div>
                            <br />
                            <h2>การเก็บรวบรวมข้อมูลส่วนบุคคลของคุณ</h2>
                            <p>ข้อมูลส่วนบุคคล หมายถึงข้อมูลเกี่ยวกับผู้ใช้งาน หรือ ลูกค้า ของ ttps://www.puiyahubkaset.com/home /
                                (&ldquo;เว็บไซต์&rdquo;) ที่สามารถระบุตัวตนผู้ใช้งาน ไม่ว่าทางตรงหรือทางอ้อม
                                ไม่ว่าจะเป็นในรูปแบบเอกสาร หรือ Digital</p>
                            <br />
                            <h3>ข้อมูลส่วนบุคคลที่คุณให้กับร้านค้า</h3>
                            <p>คุณอาจเลือกที่จะให้ข้อมูลส่วนบุคคลของคุณกับร้านค้าได้ เช่น การสมัครสมาชิกกับร้านค้า (กด Join
                                ที่หน้าเว็บไซต์) การสั่งซื้อสินค้า การกรอกฟอร์มที่ปรากฎบนเว็บไซต์ของร้านค้า
                                หรือผ่านช่องทางการตลาดบนสื่อสังคมออนไลน์ (Social Media) การเข้าร่วมกิจกรรมทางการตลาดของร้านค้า
                                หรือการติดต่อร้านค้า ผ่านอีเมล โทรศัพท์ ช่องทางการติดต่อผ่านสื่อสังคมออนไลน์ หรือช่องทางอื่น ๆ</p>
                            <ol>
                                <li>ข้อมูลพื้นฐานเกี่ยวกับตัวคุณ ได้แก่ ชื่อ วันเกิด เพศ อายุ หมายเลขโทรศัพท์ ที่อยู่อีเมล ที่อยู่
                                    รูปโปรไฟล์ และชื่อผู้ใช้ (username) ของคุณ</li>
                                <li>ข้อมูลเกี่ยวกับการสั่งซื้อสินค้าของคุณ ได้แก่ วันและเวลาซื้อสินค้า วันส่งสินค้า
                                    ราคาสินค้าที่คุณซื้อ หมายเลขคำสั่งซื้อ รายการสินค้า (SKU) ที่คุณซื้อ
                                    ช่องทางที่คุณติดต่อเข้ามาเพื่อสั่งซื้อสินค้า โปรโมชั่นที่ใช้ในการซื้อสินค้า</li>
                                <li>ข้อมูลธุรกรรมการชำระเงินที่เกี่ยวข้องกับรายการสั่งซื้อของคุณ ช่องทางที่คุณชำระเงินค่าสินค้า
                                    เวลาที่คุณชำระเงินค่าสินค้า จำนวนที่คุณชำระเงินค่าสินค้า</li>
                                <li>ข้อมูลฝ่ายลูกค้าสัมพันธ์ ในกรณีที่คุณติดต่อเราผ่านทาง โทรศัพท์ อีเมล สื่อสัมคมออนไลน์ หรือ
                                    โดยกรอกแบบฟอร์มออนไลน์ เราอาจบันทึกคำถาม และคำตอบของคุณเก็บไว้ในฐานข้อมูลลูกค้าของเรา
                                    พร้อมข้อมูลส่วนตัวพื้นฐานของคุณ ตามข้อ 1. และ/หรือ ข้อมูลส่วนบุคคลอื่น ๆ ที่คุณมอบให้</li>
                                <li>ข้อมูลทางการตลาด และการสื่อสาร ได้แก่ ช่องทาง และวิธีการสื่อสาร
                                    เพื่อทำการตลาดจากเราและบุคคลภายนอกที่ร่วมมือกับเรา ข้อมูลการเข้าร่วมกิจกรรมของคุณกับร้านค้า
                                    ข้อมูลความสนใจของคุณ ช่องทางที่คุณใช้ติดต่อกับร้านค้า ข้อมูลสินค้า และหมวดหมู่สินค้าที่คุณสนใจ
                                    และข้อมูลอื่น ๆ ที่คุณได้กรอกฟอร์มเข้ามาผ่านทางหน้าเว็บไซต์</li>
                            </ol>
                            <p>หากคุณเลือกสมัครสมาชิก หรือ เข้าสู่ระบบของเว็บไซต์
                                โดยการเชื่อมต่อบัญชีสื่อสังคมออนไลน์ของคุณกับทางเว็บไซต์
                                คุณได้อนุญาตให้เราเข้าถึงข้อมูลส่วนตัวของคุณในบัญชีสื่อสังคมออนไลน์
                                และสมัครใจในการให้ข้อมูลตามนโยบายของสื่อสังคมออนไลน์นั้น ๆ</p>
                            <p>หากข้อมูลที่คุณให้แก่เราเป็นข้อมูลส่วนบุคคลของบุคคลภายนอก
                                เราถือว่าคุณได้รับรองและรับประกันแล้วว่าคุณได้รับความยินยอม หรือ
                                การอนุญาตตามที่ควรจะเป็นจากบุคคลภายนอกดังกล่าวเรียบร้อยแล้ว ที่จะนำมาเปิดเผย
                                และส่งต่อข้อมูลส่วนบุคคลของบุคคลนั้นให้แก่เรา เพื่อจุดประสงค์ในการใช้งานเว็บไซต์ การใช้บริการ
                                และการใช้ตามวัตถุประสงค์อื่นใดที่ได้ระบุตามนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้</p>
                            <br />
                            <h3>ข้อมูลส่วนบุคคลของคุณ ที่ร้านค้าเก็บรวบรวม</h3>
                            <p>เมื่อคุณเข้าสู่เว็บไซต์ หรือ สั่งซื้อสินค้าผ่านทางช่องทางอื่น ๆ ของร้านค้า
                                เราอาจเก็บรวบรวมข้อมูลที่เกี่ยวกับตัวคุณ
                                เฉพาะข้อมูลที่จำเป็นตามวัตถุประสงค์ของการดำเนินงานของร้านค้า ดังนี้</p>
                            <ol>
                                <li>ข้อมูลทางเทคนิค เช่น IP address, MAC address, Cookie, ID, ข้อมูลการเข้าใช้,
                                    ชนิดและรุ่นของเว็บบราวเซอร์ (Web Browser), การตั้งเขตเวลาและสถานที่,
                                    ชนิดและรุ่นของโปรแกรมเสริมของเว็บบราวเซอร์, โดเมนเนม (Domain Name), บันทึกหน้าเว็บ (Web Page),
                                    ของเว็บไซต์ที่ผู้ใช้เยี่ยมชม, เวลาที่เยี่ยมชมเว็บไซต์ (Access Times),
                                    เว็บไซต์ที่ผู้ใช้บริการเข้าถึงก่อนหน้านั้น (Referring Website Addresses), ระบบปฏิบัติการ
                                    (Operation System), รหัสสากลประจำอุปกรณ์เคลื่อนที่ (IMEI), หมายเลขประจำเครื่อง (Serial Number)
                                    และข้อมูลและเทคโนโลยีอื่นของอุปกรณ์ที่คุณใช้ในการเข้าถึงเว็บไซต์</li>
                                <li>ข้อมูลการใช้เว็บไซต์ เช่น ข้อมูลว่าคุณเข้าถึงสินค้าและการบริการอย่างไร
                                    หรือเข้าดูเนื้อหาใดบนเว็บไซต์ รวมถึงเวลาที่ใช้งานเว็บไซต์</li>
                            </ol>
                            <p>เราจะเก็บรวบรวมข้อมูลส่วนบุคคลของคุณ เมื่อคุณยินยอมให้ข้อมูลส่วนบุคคลกับเราโดยสมัครใจ
                                ยกเว้นการเก็บรวบรวมข้อมูล เพื่อใช้ในกรณีดังต่อไปนี้</p>
                            <ol>
                                <li>เพื่อปฏิบัติตามสัญญา กรณีการเก็บรวบรวม ใช้
                                    หรือเปิดเผยข้อมูลส่วนบุคคลเพื่อความจำเป็นต่อการให้บริการหรือปฏิบัติตามสัญญาระหว่างเจ้าของข้อมูลและร้านค้า
                                </li>
                                <li>เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพ</li>
                                <li>เพื่อปฏิบัติตามกฎหมาย</li>
                                <li>เพื่อผลประโยชน์อันชอบโดยกฎหมายของบริษัทฯ
                                    กรณีมีความจำเป็นเพื่อประโยชน์อันชอบธรรมในการดำเนินงานของ บริษัทฯ โดยบริษัทฯ
                                    จะพิจารณาถึงสิทธิของเจ้าของข้อมูลเป็นสำคัญ เช่น เพื่อป้องกันการฉ้อโกง
                                    การรักษาความปลอดภัยในระบบเครือข่าย การปกป้องสิทธิเสรีภาพ และประโยชน์ของเจ้าของข้อมูลเป็นต้น</li>
                                <li>เพื่อการศึกษาวิจัยหรือสถิติ
                                    กรณีที่มีการจัดทำเอกสารประวัติศาสตร์หรือจดหมายเหตุเพื่อประโยชน์สาธารณะหรือที่เกี่ยวกับการศึกษาวิจัยหรือสถิติซึ่งได้จัดให้มีมาตรการปกป้องที่เหมาะสมเพื่อคุ้มครองสิทธิ
                                    และเสรีภาพของเจ้าของข้อมูล</li>
                                <li>เพื่อปฏิบัติภารกิจของรัฐ กรณีมีความจำเป็นต่อการปฏิบัติตามภารกิจเพื่อประโยชน์สาธารณะ
                                    หรือการปฏิบัติหน้าที่ตามอำนาจรัฐที่ บริษัท ได้รับมอบหมาย</li>
                            </ol>
                            <br />
                            <h3>ข้อมูลจากแหล่งอื่น</h3>
                            <p>ร้านค้าอาจได้รับข้อมูลส่วนบุคคลของคุณจากบุคคลที่สามที่เราร่วมทำงานด้วย รวมถึง แพลตฟอร์มร้านค้าออนไลน์
                                ตลาดออนไลน์ ตัวแทนของร้านค้า ผู้แนะนำสินค้า ผู้ให้บริการชำระเงิน ผู้ให้บริการขนส่ง
                                ตัวแทนของร้านค้าในการทำการตลาด เป็นต้น โดยร้านค้าได้คัดเลือกผู้ให้บริการแพลตฟอร์ม และ
                                ช่องทางการขายที่ได้มาตรฐาน และน่าเชื่อถือ โดยให้ความสำคัญกับความปลอดภัยของข้อมูลของคุณเป็นสำคัญ</p>
                            <br />
                            <h3>ระยะเวลาในการเก็บข้อมูล</h3>
                            <p>โดยทั่วไป ร้านค้าจะเก็บรวบรวมข้อมูลของลูกค้าไว้เท่าที่จำเป็นเพื่อให้บริการ และ/หรือ ปฎิบัติตามสัญญา
                                ภาระผูกพันต่าง ๆ เท่าที่จำเป็นตามสมควรตามวัตถุประสงค์ที่ได้ชี้แจงไว้ในนโยบายความเป็นส่วนตัวนี้
                                หรือตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลที่เกี่ยวข้องกำหนดไว้
                                โดยร้านค้าอาจเก็บรักษาข้อมูลที่มีเหตุอันสมควร ที่อาจเป็นข้อพิพาท/คดีความ
                                ตามที่กฎหมายที่เกี่ยวข้องอนุญาตให้ทำได้ ทั้งนี้
                                เราจะใช้ความพยายามอย่างดีที่สุดในการจัดให้มีมาตรการการรักษาความปลอดภัยของข้อมูลส่วนบุคคลของลูกค้า
                                เพื่อป้องกันการสูญหาย เข้าถึง ใช้ เปลี่ยนแปลง แก้ไข หรือเปิดเผยข้อมูลส่วนบุคคลของคุณโดยมิชอบ
                                และร้านค้าจะลบหรือทำลายข้อมูลส่วนบุคคล
                                หรือทำให้เป็นข้อมูลที่ไม่สามารถระบุถึงตัวตนของผู้ใช้ได้เมื่อหมดความจำเป็น</p>
                            <br />
                            <h2>วัตถุประสงค์การเก็บรวบรวมข้อมูลส่วนบุคคล</h2>
                            <p>ร้านค้าจะเก็บรวบรวมข้อมูลส่วนบุคคลของคุณ โดยมีจุดประสงค์หลักในการนำเสนอสินค้า บริการ
                                และประสบการณ์ในการใช้งานเว็บไซต์ที่เหมาะสมที่สุดสำหรับลูกค้า โดยมีรายละเอียดดังต่อไปนี้</p>
                            <ol>
                                <li>เพื่ออำนวยความสะดวกในการใช้งานเว็บไซต์ รวมไปถึงการให้บริการ เช่น การสั่งซื้อสินค้า
                                    การติดต่อลูกค้า การอำนวยความสะดวกลูกค้าในการให้บริการ</li>
                                <li>เพื่อตรวจสอบและดำเนินธุรกรรมทางการเงินที่เกี่ยวข้องกับการชำระเงินออนไลน์ของคุณ</li>
                                <li>เพื่อตรวจสอบ และแจ้งให้คุณทราบเกี่ยวกับการขนส่งสินค้าของคุณ</li>
                                <li>เพื่อการพัฒนาคุณภาพสินค้า และ บริการของร้านค้า</li>
                                <li>เพื่อตอบสนองข้อร้องเรียน และ ข้อเสนอแนะของคุณ</li>
                                <li>เพื่อทำการตลาด สื่อโฆษณา และการส่งเสริมการขาย เกี่ยวกับสินค้า และ บริการของร้านค้า</li>
                                <li>เพื่อวิเคราะห์ประสิทธิภาพของการให้บริการ พฤติกรรมของลูกค้า สื่อโฆษณา และการส่งเสริมการขาย</li>
                                <li>เราอาจแชร์ข้อมูลที่ไม่ระบุตัวตนของคุณสู่สาธารณะ หรือ พาร์ทเนอร์ หรือเพื่อจุดประสงค์ทางการตลาด
                                    การวิเคราะห์ประสิทธิภาพของเว็บไซต์ และการดำเนินงาน การส่งเสริมการขาย
                                    หรือเพื่อแสดงเทรนด์เกี่ยวกับการใช้บริการของเราโดยทั่วไป</li>
                                <li>เราอาจใช้ข้อมูลของคุณเพื่อประมวลผลการตัดสินใจแบบอัตโนมัติตามวัตถุประสงค์ที่ได้กล่าวไว้ข้างต้น
                                </li>
                            </ol>
                            <br />
                            <h2>การเปิดเผยข้อมูลส่วนบุคคลของคุณ</h2>
                            <p>ร้านค้าไม่มีนโยบายเปิดเผยข้อมูลส่วนบุคคลของคุณกับบุคคลภายนอก เว้นแต่ในกรณีดังต่อไปนี้</p>
                            <ol>
                                <li>เพื่อบรรลุวัตถุประสงค์ตามที่ระบุไว้ในนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้
                                    ร้านค้าอาจจำเป็นต้องเปิดเผยข้อมูลเฉพาะเท่าที่จำเป็นให้แก่คู่ค้า ผู้ให้บริการ หรือ
                                    หน่วยงานภายนอกที่เกี่ยวข้องกับการให้บริการของร้านค้า</li>
                                <li>เพื่อปฏิบัติตามกฎหมาย หรือมีกระบวนการทางกฎหมายบังคับให้ ร้านค้าจำเป็นต้องเปิดเผยข้อมูล
                                    ต่อเจ้าพนักงาน เจ้าหน้าที่รัฐ หรือ หน่วยงานที่มีอำนาจ</li>
                                <li>เพื่อให้ข้อมูลจำเป็นต่อการคุ้มครองชีวิต ร่างกายหรือทรัพย์สินของบุคคล
                                    และกรณีที่จะขอความยินยอมจากบุคคลนั้นเป็นการยากที่จะกระทำได้</li>
                                <li>เพื่อให้ข้อมูลจำเป็นต่อความร่วมมือกับองค์กรของรัฐ รัฐบาลท้องถิ่น
                                    หรือบุคคลหรือผู้ประกอบการที่ได้รับมอบหมายให้ทำกิจการต่างๆ ตามที่กฎหมายหรือกฎระเบียบกำหนด</li>
                            </ol>
                            <br />
                            <h3>การเปิดเผยข้อมูลต่อบุคคลภายนอก</h3>
                            <p>เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณให้แก่บุคคลที่สาม ที่ร้านค้าไว้วางใจ และใช้บริการ ซึ่งได้แก่</p>
                            <ol>
                                <li>แพลตฟอร์มร้านค้าออนไลน์ puiyahubkaset.com ที่ร้านค้าใช้บริการด้านเทคโนโลยี
                                    เพื่อประกอบกิจการออนไลน์</li>
                                <li>ผู้ให้บริการพื้นที่เก็บ แพ็ค และ ส่งสินค้า</li>
                                <li>พื้นที่ตลาดออนไลน์ หรือ สื่อสังคมออนไลน์ และ ผู้ประกอบธุรกิจอื่น ๆ ในลักษณะเดียวกัน</li>
                                <li>ผู้ให้บริการวิเคราะห์ข้อมูลภายในเว็บไซต์ และ ผู้ให้บริการอื่น ๆ ในลักษณะเดียวกัน</li>
                                <li>ตัวแทนโฆษณา การตลาดดิจิทัล ที่ช่วยเหลือให้ร้านค้าจัดทำโฆษณา กิจกรรมส่งเสริมการขาย เพื่อการจัดการ
                                    และวิเคราะห์ประสิทธิภาพของโฆษณา หรือ กิจกรรมที่จัดขึ้น</li>
                            </ol>
                            <p>โดยที่บุคคลภายนอกตามที่กล่าวไว้เหล่านี้ อาจมีการใช้คุกกี้ (Cookies) และ/หรือ เทคโนโลยีอื่น ๆ
                                ในการเก็บข้อมูลในเว็บไซต์ หรือจากแหล่งเครือข่ายบนอินเตอร์เน็ต
                                และสามารถใช้ข้อมูลเหล่านี้ในการส่งโฆษณาที่เหมาะสมแก่ท่านได้ (Retargeting)
                                ร้านค้าจะเปิดเผยข้อมูลส่วนบุคคลของท่านเท่าที่จำเป็นต่อการทำงานเท่านั้น
                                โดยร้านค้าจะพยายามอย่างดีที่สุด เพื่อทำให้แน่ใจว่า บุคคลภายนอกที่ร้านค้าร่วมมือด้วยนั้น
                                จะเก็บรักษาข้อมูลส่วนบุคคลของคุณให้ปลอดภัย</p>
                            <p>ทั้งนี้ร้านค้าไม่มีนโยบายขายข้อมูลส่วนบุคคลของท่าน</p>
                            <br />
                            <h2>สิทธิของคุณ</h2>
                            <p>คุณมีสิทธิในการขอถอนความยินยอมที่เคยให้ไว้กับร้าน สิทธิในการสอบถามร้านค้า หรือ
                                ร้องขอสำเนาข้อมูลส่วนบุคคลของคุณ สิทธิในการปรังปรุงข้อมูลของคุณ
                                สิทธิในการขอให้ร้านค้าลบข้อมูลส่วนบุคคลของคุณ และสิทธิในการขอให้ร้านค้าไม่ติดต่อคุณ
                                โดยร้านค้าจะไม่ติดต่อคุณเพื่อจุดประสงค์ทางการตลาด เว้นแต่จะได้รับความยินยอมจากคุณก่อนเสมอ</p>
                            <p>คุณต้องยอมรับ และ เข้าใจว่าการเลือกที่จะไม่ให้ข้อมูล หรือถอดถอนสิทธิที่เคยให้ไว้
                                อาจมีผลกระทบกับการให้บริการของร้านค้าที่คุณจะได้รับ เราอาจจะไม่สามารถให้บริการ
                                หรือปฏิบัติตามสัญญาที่มีแก่คุณได้ และเราจะไม่รับผิดชอบในกรณีนี้ โดยเราขอสงวนสิทธิ์ทางกฎหมาย
                                และการเยียวยาของเราโดยชัดแจ้งสำหรับกรณีดังกล่าว
                                เราแนะนำให้คุณศึกษาและสอบถามถึงผลกระทบก่อนถอนความยินยอม</p>
                            <p>การถอดถอนความยินยอม สอบถาม ร้องขอสำเนา หรือ การขอให้ลบข้อมูลส่วนบุคคลของคุณ อาจไม่สามารถทำได้ในทันที
                                และอาจมีค่าดำเนินงานบางส่วนตามความเหมาะสม โดยคุณสามารถสอบถามเราได้เสมอเพื่อทราบขั้นตอน
                                และค่าดำเนินงานดังกล่าว</p>
                            <p>คุณมีสิทธิปิดการทำงานของคุกกี้ ผ่านทางเว็บบราวเซอร์ที่ใช้งานอยู่ได้ด้วยตัวเอง
                                โดยไม่จำเป็นต้องแจ้งให้เราทราบ
                                แต่การปิดกั้นคุ้กกี้อาจทำให้คุณใช้งานเว็บไซต์ของเราได้ไม่เต็มที่ในบางส่วน</p>
                            <br />
                            <h2>ความถูกต้องของข้อมูลส่วนบุคคลของคุณ</h2>
                            <p>ความถูกต้องของข้อมูลส่วนบุคคลของคุณมีความสำคัญมาก เพื่อให้คุณได้รับสินค้า และบริการอย่างราบรื่น
                                ร้านค้าอยากให้คุณมั่นใจว่า</p>
                            <ol>
                                <li>ข้อมูลส่วนบุคคลที่คุณส่งให้นั้น ถูกต้อง และไม่ก่อให้เกิดความเข้าใจผิด</li>
                                <li>คุณต้องหมั่นแก้ไขข้อมูลดังกล่าวให้เป็นปัจจุบัน
                                    และแจ้งให้เราทราบหากมีการเปลี่ยนแปลงข้อมูลส่วนบุคคลใด ๆ ที่มีผลกระทบกับการให้บริการ
                                    เรามีสิทธิ์ที่จะร้องขอเอกสารเพื่อยืนยันความถูกต้องของข้อมูลส่วนบุคคลที่คุณเคยได้ให้ไว้
                                    เพื่อเป็นส่วนหนึ่งในขั้นตอนการตรวจสอบลูกค้าของเรา</li>
                                <li>คุณสามารถแก้ไขข้อมูลส่วนบุคคลของคุณให้เป็นปัจจุบันได้ตลอดเวลา
                                    โดยการเข้าถึงซึ่งบัญชีผู้ใช้ของคุณบนเว็บไซต์</li>
                            </ol>
                            <br />
                            <h2>การรักษาความปลอดภัยของข้อมูลส่วนบุคคล</h2>
                            <p>เราให้ความสำคัญต่อความปลอดภัยของข้อมูลส่วนบุคคลของคุณ โดยข้อมูลที่เก็บรวบรวมทั้งหมด
                                จะถูกเก็บรักษาไว้อย่างปลอดภัย โดยมีมาตรการดังนี้</p>
                            <ol>
                                <li>จำกัดการเข้าถึงข้อมูลส่วนบุคคล ให้เปิดเผยเฉพาะข้อมูลที่จำเป็นต่อการให้บริการเท่านั้น</li>
                                <li>จัดให้มีช่องทางการสื่อสารแบบปลอดภัยสำหรับข้อมูลดังกล่าวด้วยการเข้ารหัสลับข้อมูลด้วย
                                    ใช้เทคโนโลยีการเข้ารหัสข้อมูล หรือ Secure Socket Layer (SSL) protocol</li>
                                <li>เราจะลบหรือทำลายข้อมูลส่วนบุคคลของคุณ เมื่อเห็นสมควรแล้วว่าการเก็บรักษาข้อมูลนั้น
                                    ไม่เป็นไปตามวัตถุประสงค์ตามที่ได้ระบุไว้ในนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้
                                    และไม่มีความจำเป็นสำหรับวัตถุประสงค์ทางกฎหมาย หรือทางธุรกิจอื่นใด</li>
                            </ol>
                            <br />
                            <h2>เว็บไซต์ของบุคคลภายนอก</h2>
                            <p>ร้านค้าอาจมีการเชื่อมลิ้งค์ไปยังเว็บไซต์อื่น โดยอาจเป็นเว็บไซต์ของผู้ที่เกี่ยวข้องกับเราทางธุรกิจ
                                ช่องทางการขาย ช่องทางการชำระเงิน หรือ ช่องทางการส่งสินค้า
                                เราขอแนะนำให้คุณตรวจสอบนโยบายคุ้มครองข้อมูลส่วนบุคคลของเว็บไซต์ที่เชื่อมโยงเหล่านี้
                                เพื่อจะได้ทราบนโยบายการคุ้มครองข้อมูลส่วนบุคคลของคุณในเว็บไซต์ดังกล่าว
                                เราไม่สามารถรับรองความปลอดภัยของข้อมูลของคุณภายในเว็บไซต์ดังกล่าวได้ และไม่ขอรับผิดชอบใด ๆ
                                หากเว็บไซต์เหล่านั้นไม่ได้ปฏิบัติการหรือดำเนินการใด ๆ
                                ตามนโยบายคุ้มครองข้อมูลส่วนบุคคลที่เว็บไซต์ดังกล่าวได้ประกาศไว้</p>
                            <br />
                            <h2>การปรับปรุงนโยบายคุ้มครองข้อมูลส่วนบุคคล</h2>
                            <p>ร้านค้าอาจปรับปรุงหรือแก้ไขนโยบายคุ้มครองข้อมูลส่วนบุคคลนี้ในอนาคต
                                เพื่อความเหมาะสมและมีประสิทธิภาพในการให้บริการ โดยไม่ได้แจ้งให้คุณทราบล่วงหน้า
                                เราจึงแนะนำให้คุณตรวจสอบข้อกำหนดและเงื่อนไขอย่างสม่ำเสมอเพื่อทราบถึงความเปลี่ยนแปลงใด ๆ ที่เกิดขึ้น
                            </p>
                            <br />
                            <h2>ติดต่อร้านค้า</h2>
                            <p>ในกรณีที่คุณมีข้อสงสัย ข้อเสนอแนะ หรือข้อติชมใด ๆ เกี่ยวกับนโยบาย ความเป็นส่วนตัว หรือ
                                ต้องการแจ้งใช้สิทธิตามนโยบายนี้ หรือการปฏิบัติตามนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้
                                เรายินทีที่จะตอบข้อสงสัย รับฟังข้อเสนอแนะ และคำติชมทั้งหลาย
                                อันจะเป็นประโยชน์ต่อการปรับปรุงการให้บริการของร้านค้าต่อไป โดยคุณสามารถติดต่อกลับมาได้ที่</p>
                            <p><b> ผู้ควบคุมข้อมูลส่วนบุคคล </b></p>
                            <p>HubKaset</p>
                            <p>0623735224, hubkaset@hotmail.com</p>
                            หรือติดต่อผ่านแบบฟอร์มได้ที่ <a target="_blank" href="https://www.puiyahubkaset.com/home"
                                rel="noopener">https://www.puiyahubkaset.com/home</a>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->