export class Customer {
    id: string;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    privilegeId: string;
    authType: string;
    token: string;
    point: number;
    isCollectPoint: string;
}