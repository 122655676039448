<app-breadcrumb [title]="'Login'" [breadcrumb]="'Login'"></app-breadcrumb>
<!-- section start -->
<section class="login-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>Login</h3>
                <div class="theme-card">
                    <form class="theme-form" [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="text" class="form-control" id="email" placeholder="Email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="review">Password</label>
                            <input type="password" class="form-control" id="review" placeholder="Enter your password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                            </div>
                        </div>
                        <button class="btn btn-solid" style="font-size:18px;margin-right: 5px;height: 50.8px;padding: 13px 20px;width: 123px;">Login</button>
                        <button class="btn btn-solid" style="font-size:18px;margin-right: 5px;padding: 13px 20px;background: #282864;" (click)="facebookLogin($event)"><img src="assets/images/facebook-icon2.png" style="height: 20px;
                            margin: auto;
                            vertical-align: text-top;">Login</button>
                        <button class="btn btn-solid" style="font-size:18px;padding: 13px 20px;background: #22ab22;" (click)="lineLogin($event)"><img src="assets/images/line-icon.png" style="height: 18px;
                            margin: auto;
                            vertical-align: top;
                            margin-right: 3px;">Login</button>
                        <!-- <a href="#" (click)="facebookLogin($event)"><img src="assets/images/facebook-login-image.png" style="margin-left: 10px; height: 40px;"></a> -->
                        <!-- <a href="https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657251450&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Flogin&state=abcd1234&scope=profile%20openid%20email"><img src="https://i.stack.imgur.com/LKMP7.png" style="margin-left: 10px;"></a> -->
                        <!-- <a href="https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657251450&redirect_uri=https%3A%2F%2Fhubkasetweb.azurewebsites.net%2Flogin&state=login20220&scope=profile%20openid%20email"><img src="assets/images/line-login-image.png" style="margin-left: 10px;"></a> -->
                    </form>
                </div>
            </div>
            <div class="col-lg-6 right-login">
                <h3>New Customer</h3>
                <div class="theme-card authentication-right">
                    <h6 class="title-font">Create A Account</h6>
                    <p>Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.</p>
                    <button class="btn btn-solid" [routerLink]="['/register']">Create an Account</button>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->