<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li>Welcome to Our store HubKaset</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: 062-373-5224</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-right">
          <ul class="header-dropdown">
            <li class="compare">
              <!-- <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a> -->
            </li>
            <li class="mobile-account" *ngIf="customer">
              <i class="fa fa-star" aria-hidden="true"></i>{{customer?.point | number:'1.0-0'}} Point
            </li>
            <li class="onhover-dropdown mobile-account">
              <i class="fa fa-user" aria-hidden="true"></i> 
              <ng-container *ngIf="customer">{{customer.firstName}} {{customer.lastName}} </ng-container>
              <ng-container *ngIf="!customer">Guest</ng-container>
              <ul class="onhover-show-div">
                <li *ngIf="!customer">
                  <a [routerLink]="['/login']">Login</a>
                </li>
                <li *ngIf="customer">
                  <a [routerLink]="['/pages/profile']">Profile</a> 
                </li>
                <li *ngIf="customer">
                  <a [routerLink]="['/pages/order-history']">Order History</a> 
                </li>
                <li *ngIf="customer">
                  <a data-lng="es" (click)="logout()">
                    Logout
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <!-- <app-left-menu></app-left-menu> -->
            <div class="brand-logo">
              <a routerLink="/home/fashion" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo" style="max-width: 100px;">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
