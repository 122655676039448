import { Customer } from './../model/customer';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { ProductService } from './product.service';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  public customerSubject: BehaviorSubject<Customer>;
  public customer: Observable<Customer>;

  constructor(
    private http: HttpService,
    private router: Router,
    private socialAuthService: SocialAuthService,
    private productService: ProductService
  ) { 
    this.customerSubject = new BehaviorSubject<Customer>(JSON.parse(localStorage.getItem('customer')));
    this.customer = this.customerSubject.asObservable();
  }

  public get customerValue(): Customer { 
    return this.customerSubject.value;
  }

  login(email: string, password: string) {
    const req = {
      Email: email,
      Password: password
    }
    return this.http.post('Customers/Login', req);
  }

  loginSocial(req: any) {
    return this.http.post('Customers/loginSocial', req);
  }

  getProfile(){
    return this.http.post('Customers/profile');
  }

  getCustomerPoint(){
    return this.http.post('Customers/Point');
  }

  deleteCustomer(){
    return this.http.post('Customers/Delete');
  }

  updateProfile(req: any) {
    return this.http.post('Customers/profile/update', req);
  }

  register(req: any) {
    return this.http.post('Customers/register', req);
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('customer');
    localStorage.removeItem("cartItems");
    // this.socialAuthService.signOut();
    this.customerSubject.next(null);
    this.productService.clearCartItem();
    this.socialAuthService.signOut().finally(()=>{
      this.router.navigate(['/login']);
    })
  }



}
