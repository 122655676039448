import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ToastrService } from 'ngx-toastr';
import { CustomersService } from 'src/app/shared/services/customers.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  form: FormGroup;
  submitted = false;

  constructor(
    private loader: LoadingBarService,
    private formBuilder: FormBuilder,
    private customersService: CustomersService,
    private toastrService: ToastrService,
    private router: Router,
  ) { }

  get f() { return this.form.controls; }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: ['', Validators.required],
      firstname: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      lastname: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      email: ['', [Validators.required, Validators.email]],
    });

  }

  onSubmit() {
    console.log('onSubmit');

    this.submitted = true;

    // reset alerts on submit
    // this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      console.log('invalid');
      return;
    }

    const req: any = {};
    req.FirstName = this.form.controls['firstname'].value;
    req.LastName = this.form.controls['lastname'].value;
    req.Password = this.form.controls['password'].value;
    req.Email = this.form.controls['email'].value;

    this.loader.start();
    this.customersService.register(req)
      .subscribe(
        (data: any) => {
          if (data.status == "Error") {
            this.toastrService.error(data.message, null, {
              positionClass: 'toast-bottom-center'
            });
            return;
          } else {
            console.log(data);
            this.router.navigate(['/login']);
            this.loader.stop();
          }
        },
        error => {
          console.log('error', error);
          if (error.status === 401) {
            // this.toastrService.error('email or password is invalid.', null, {
            //   positionClass: 'toast-bottom-center'
            // });
          }
          this.loader.stop();
        });
  }

}
