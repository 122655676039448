import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { UtilsService } from '../../services/utils.service';
import { CustomersService } from '../../services/customers.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems: Menu[];

  constructor(private router: Router, 
    public utilsService: UtilsService,
    public navServices: NavService,
    private customersService : CustomersService
    ) {
    this.utilsService.getMenu().subscribe((res: any) => {
      console.log(res);
      this.menuItems = res.map((e) => {
        return <Menu>
          {
            title: e.categoryNameTH,
            active: false,
            type: 'sub',
            children: e.subCategoryList.map((c) => {
              return <Menu>
                {
                  title: c.nameTH,
                  type: 'link',
                  path: `/pages/${e.categoryNameEN}/${c.nameEN}`
                };
            })
           };
      })

      // const customer = this.customersService.customerValue;
      // if(customer.isCollectPoint === 'Y'){
      //   this.menuItems.push({
      //     title: "Redeem",
      //     type: 'link',
      //     path: `/shop/redeem/search`
      //   })
      // }
      this.menuItems.push({
        title: "ใช้แต้ม",
        type: 'link',
        path: `/shop/redeem/search`
      })

      // console.log('this.menuItems', this.menuItems);
      
    })
    // this.navServices.items.subscribe(menuItems => this.menuItems = menuItems );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

}
