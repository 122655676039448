import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private http: HttpService,
  ) { }

  getMenu(){
    return this.http.get('test/menus');
  }

  getProvinceList(){
    return this.http.post('province/list');
  }

  getAmphureList(provinceId){
    const req = { ProvinceId: provinceId}
    return this.http.post('amphure/list', req);
  }

  getTambonList(amphureId){
    const req = { AmphureId: amphureId}
    return this.http.post('tambon/list', req);
  }

  getPaymentType(){
    return this.http.post('paymentType/list');
  }

  getDeliveryPartner(){
    return this.http.post('deliveryPartner/list');
  }

  validategetDiscountCode(code,totalPrice){
    const req = { Code: code, TotalPrice: totalPrice}
    return this.http.post('DiscountCode/validate', req);
  }

  getSystemConfig(req) {
    return this.http.post('systemConfig/get', req);
  }

  getBannerList() {
    return this.http.post('banner/list');
  }
  
}
