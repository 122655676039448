import { CustomersService } from './../customers.service';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators'

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthenticationHttpInterceptor implements HttpInterceptor {

    constructor(private customersService : CustomersService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handleHeader()).pipe(
            switchMap((newHeaders) => {
                const newReq = req.clone({ headers: newHeaders });
                return next.handle(newReq);
            }));
    }

    

    async handleHeader() {
        const customer = this.customersService.customerValue;
        // console.log(customer);
        
        let headers = new HttpHeaders()
            // .set("Accept-Encoding", "gzip, deflate, br")
            // .set("Content-Type", "application/x-www-form-urlencoded")
            // .set("App-Version", environment.APP_VERSION || '')
            // .set("Device-Type", await this.globalService.getPlatformName() || 'IOS')
            // .set("Device-Version", await this.globalService.getDeviceVersion() || '')
            // .set("User-Id", localStorage.getItem("_authen_user_id") || '')
            // .set("User-Name", localStorage.getItem("_authen_name") || '')
            .set("Authorization", "bearer " + customer?.token || '')
            // .set("timeout", HTTP_REQUEST_TIMEOUT.toString() || '60000');

        return headers;
    }


}
