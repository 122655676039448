<app-breadcrumb [title]="'Register'" [breadcrumb]="'Register'"></app-breadcrumb>
<!-- section start -->
<section class="register-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3>create account</h3>
                <div class="theme-card">
                    <form class="theme-form" [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="form-row">
                            <div class="col-md-6">
                                <label for="email">First Name</label>
                                <label class="text text-danger" style="margin-left: 5px;"
                                    *ngIf="submitted && f.firstname.errors?.required">(First
                                    Name is required.)</label>
                                <label class="text text-danger" style="margin-left: 5px;"
                                    *ngIf="submitted && f.firstname.errors?.pattern">(First
                                    Name must be an alphabates.)</label>
                                <input type="text" class="form-control" id="fname" placeholder="First Name" 
                                formControlName="firstname" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                            </div>
                            <div class="col-md-6">
                                <label for="review">Last Name</label>
                                <label class="text text-danger" style="margin-left: 5px;"
                                    *ngIf="submitted && f.lastname.errors?.required">(Last
                                    Name is required.)</label>
                                <label class="text text-danger" style="margin-left: 5px;"
                                    *ngIf="submitted && f.lastname.errors?.pattern">Last
                                    (Name must be an alphabates.)</label>
                                <input type="text" class="form-control" id="lname" placeholder="Last Name"
                                formControlName="lastname" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6">
                                <label for="email">Email</label>
                                <label class="text text-danger" style="margin-left: 5px;"
                                    *ngIf="submitted && f.email.errors?.required">(Email
                                    is required.)</label>
                                <label class="text text-danger" style="margin-left: 5px;"
                                    *ngIf="submitted && f.email.errors?.pattern">(Invalid
                                    Email.)</label>
                                <input type="text" class="form-control" id="email" placeholder="Email"
                                    formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                            </div>
                            <div class="col-md-6">
                                <label for="review">Password</label>
                                <label class="text text-danger" style="margin-left: 5px;"
                                    *ngIf="submitted && f.password.errors?.required">(Password
                                    is required.)</label>
                                <input type="password" class="form-control" id="review"
                                    placeholder="Enter your password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                            </div>
                            <button class="btn btn-solid">create Account</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->