import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, zip } from 'rxjs';
import { JwtHelperService } from "@auth0/angular-jwt";
import { CustomersService } from '../customers.service';
import { ToastrService } from 'ngx-toastr';
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private customersService: CustomersService,
        private router: Router,
        private toastrService: ToastrService,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {

        const jwthelper = new JwtHelperService();
        const customer = this.customersService.customerValue;
        // console.log('isTokenExpired', jwthelper.isTokenExpired(customer.token));
        if (customer && jwthelper.isTokenExpired(customer.token)) {
            this.toastrService.error('login invalid.', null, {
                positionClass: 'toast-bottom-center'
              });
            localStorage.removeItem('customer');
            this.customersService.customerSubject.next(null);
            this.router.navigate(['/login']);
            return false;
        } else {
            return true;
        }
    }

}
