import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthenticationHttpInterceptor } from "./authentication";
import { BaseUrlHttpInterceptor } from "./base-url";

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationHttpInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: TimeOutHttpInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerHttpInterceptor, multi: true },
];