import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient,
  ) { }

  get(url: string, option?: any) {
    const req = this.http.get(url, option);
    return req;
  }

  post(url: string, data?: any, option?: any) {
    const req = this.http.post(url, data || {}, option);
    return req;
  }

  // postWithLoading(url: string, data?: any, option?: any) {
  //   const req = this.http.post(url, data || {}, option);
  //   return this.loader.singlePresentObs(req);
  // }

  postMultiple<T>(obserables: Observable<T>[]) {
    const reqs = zip(...obserables);
    return reqs;
  }

  // postMultipleWithLoading<T>(obserables: Observable<T>[]) {
  //   const reqs = zip(...obserables);
  //   return this.loader.singlePresentObs(reqs);
  // }
}
